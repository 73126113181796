import { defaultDataSource } from './data_source.jsx'

export const MODEL_TYPE = {
  PHYSICAL_INVENTORY: 1,
  PURCHASE_QUOTE: 2,
  PURCHASE_ORDER: 3,
  PURCHASE_RECEIPT: 4,
  PURCHASE_INVOICE: 5,
  PURCHASE_RETURN_ORDER: 6,
  PURCHASE_RETURN_RECEIPT: 7,
  PURCHASE_CREDIT_MEMO: 8,
  PURCHASE_FINANCE_CHARGE: 9,
  SALES_QUOTE: 10,
  SALES_ORDER: 11,
  SALES_SHIPMENT: 12,
  SALES_INVOICE: 13,
  SALES_RETURN_ORDER: 14,
  SALES_RETURN_SHIPMENT: 15,
  SALES_CREDIT_MEMO: 16,
  SALES_FINANCE_CHARGE: 17,
  SALES_RETAIL_TRANSACTION: 18,
  SALES_RETAIL_REFUND_TRANSACTION: 19,
  SALES_RETAIL_ON_HOLD_TRANSACTION: 20,
  BANK_STATEMENT: 30,
  CASHIER_RECEIVES: 31,
  CASHIER_PAYS: 32,
  ACCOUNT: 33,
  PRODUCT: 34,
  PURCHASE_CONTRACT: 36,
  SALES_CONTRACT: 37,
  FIXED_ASSET: 41,
  EMPLOYEE: 42,
  RESOURCE: 43,
  SMALL_INVENTORY: 44,
  SALES_OPPORTUNITY: 47,
  BANK_ACCOUNT: 48,
  CASH_ACCOUNT: 49,
  COMPANY: 51,
  CHART_OF_ACCOUNT: 53
}

export const MODEL_TYPE_KEY_BY_VALUE = (value) => {
  return Object.keys(MODEL_TYPE).find(key => MODEL_TYPE[key] === value)
}

export const MODEL_TYPE_MAPPER = {
  [MODEL_TYPE.PRODUCT]: {
    dataSource: (model, params) => defaultDataSource.product({ model, params }),
    displayExpr: (data, languageCode) => data.name[languageCode],
    searchExpr: (languageCode) => `name->>"$.${languageCode}"`
  },
  [MODEL_TYPE.FIXED_ASSET]: {
    dataSource: (model) => defaultDataSource.fixedAsset({ model }),
    displayExpr: (data) => data.name,
    searchExpr: () => 'name'
  },
  [MODEL_TYPE.RESOURCE]: {
    dataSource: (model) => defaultDataSource.resource({ model }),
    displayExpr: (data) => data.name,
    searchExpr: () => 'name'
  },
  [MODEL_TYPE.SMALL_INVENTORY]: {
    dataSource: (model) => defaultDataSource.smallInventory({ model }),
    displayExpr: (data) => data.name,
    searchExpr: () => 'name'
  },
  [MODEL_TYPE.ACCOUNT]: {
    dataSource: (config) => defaultDataSource.account(config),
    displayExpr: (data) => data.name,
    searchExpr: () => 'name'
  },
  [MODEL_TYPE.BANK_ACCOUNT]: {
    dataSource: (model, params) => defaultDataSource.bank_account({ model, params }),
    displayExpr: (data) => data.bank_account_number + ' - ' + data.bank?.name,
    searchExpr: () => 'bank_account_number'
  },
  [MODEL_TYPE.EMPLOYEE]: {
    dataSource: (model, params) => defaultDataSource.employee({ model, params }),
    displayExpr: (data) => data.display_name,
    searchExpr: () => 'display_name'
  },
  [MODEL_TYPE.CASH_ACCOUNT]: {
    dataSource: (model, params) => defaultDataSource.cashAccount({ model, params }),
    displayExpr: (data) => data.name,
    searchExpr: () => 'name'
  },
  [MODEL_TYPE.COMPANY]: {
    dataSource: (model) => defaultDataSource.company({ model }),
    displayExpr: (data) => data.name,
    searchExpr: () => 'name'
  },
  [MODEL_TYPE.CHART_OF_ACCOUNT]: {
    dataSource: (model) => defaultDataSource.chartOfAccount({ model }),
    displayExpr: (data) => data.name,
    searchExpr: () => 'name'
  }
}
