import React from 'react'
import classNames from 'classnames'

const svgClass = (className) => classNames(
  'svg-inline',
  className
)

const LogoLight = ({ className }) => {
  return (
    <svg className={svgClass(className)} xmlns='http://www.w3.org/2000/svg' xmlSpace='preserve' viewBox='0 0 1640 400'>
      <path
        fill='#FFFFFF'
        d='M545.53 271.73c-18.85-18.5-28.27-42.58-28.27-71.55s9.42-53.05 28.62-71.55c19.2-18.85 43.63-28.27 73.64-28.27 38.74 0 72.95 19.2 88.65 53.05l-39.44 21.64c-11.52-19.2-27.92-28.97-48.51-28.97-15.71 0-28.62 5.24-38.74 15.71-10.12 10.12-15.36 23.03-15.36 38.39 0 15.71 4.89 28.62 15.01 39.09 10.12 10.12 23.04 15.36 38.04 15.36 19.9 0 40.14-11.87 48.51-28.62l39.79 23.38c-16.4 30.72-50.26 50.26-89.35 50.26-29.66 0-53.74-9.42-72.59-27.92zM737.83 295.46V105.24h47.12v25.48c11.52-20.59 28.97-30.71 52.36-30.71 7.68 0 15.36 1.4 23.04 4.19l-4.19 47.12c-8.03-2.44-15.71-3.84-22.69-3.84-27.92 0-46.42 18.15-46.42 57.59v90.4h-49.22zM1061.01 217.63H918.26c6.98 26.88 25.48 40.49 55.49 40.49 20.59 0 45.03-8.02 61.43-19.19l19.54 34.56c-24.08 17.45-51.66 26.18-83.07 26.18-33.51 0-58.99-9.77-76.79-28.97-17.8-19.2-26.52-42.93-26.52-70.5 0-28.97 9.07-52.7 27.57-71.55s42.23-28.27 71.55-28.27c27.57 0 50.61 8.73 68.41 25.83 17.8 17.1 26.87 40.14 26.87 69.81.01 6.6-.68 13.93-1.73 21.61zm-142.75-36.65h96.68c-4.54-24.78-22.34-39.79-47.47-39.79-24.78 0-43.28 15.01-49.21 39.79zM1241.43 105.24h46.77v190.22h-46.77v-21.99c-16.41 17.8-36.65 26.53-60.73 26.53-27.23 0-49.91-9.42-67.71-28.62-17.8-19.19-26.53-43.28-26.53-71.9s9.08-52.35 26.87-70.85c17.8-18.85 40.49-28.27 67.36-28.27 23.73 0 43.98 9.08 60.73 27.57v-22.69zm-14.66 56.19c-10.12-10.82-22.69-16.06-38.04-16.06-15.71 0-28.27 5.24-38.39 16.06-9.77 10.82-14.66 23.73-14.66 38.74 0 15.36 4.89 28.27 15.01 39.09 10.12 10.82 22.69 16.06 38.04 16.06 15.36 0 27.92-5.24 38.04-16.06s15.36-23.73 15.36-39.09c0-15-5.23-27.91-15.36-38.74zM1618.01 121.3c14.66 14.31 21.99 33.85 21.99 59.33v114.83h-49.21V189.7c0-28.96-15.36-44.33-38.04-44.33-24.78 0-41.88 17.8-41.88 45.72v104.36h-49.21v-105.4c0-28.27-15.01-44.68-38.39-44.68-24.08 0-41.53 17.8-41.53 46.07v104.01h-49.21V105.24h48.16v27.22c10.82-19.9 33.16-32.81 57.94-32.46 23.73 0 45.72 11.52 55.85 33.5 13.96-20.94 39.79-33.5 68.06-33.5 22.67.01 41.16 6.98 55.47 21.3z'
      />
      <path
        fill='#009965'
        d='M400 200c0 110.46-89.54 200-200 200S0 310.46 0 200 89.54 0 200 0s200 89.54 200 200z'
      />
      <path
        fill='#FFFFFF'
        d='m282.5 213.43-36.59-36.25c-2.28-2.25-5.26-3.38-8.24-3.38s-5.97 1.13-8.24 3.38c-7.57 7.5-17.64 11.63-28.35 11.63-22.11 0-40.09-17.82-40.09-39.72s17.99-39.72 40.09-39.72c10.71 0 20.78 4.13 28.35 11.63 2.19 2.17 5.15 3.38 8.24 3.38s6.06-1.22 8.24-3.38l36.59-36.25a11.474 11.474 0 0 0 0-16.33C260.75 46.87 231.83 35 201.07 35c-63.5 0-115.16 51.18-115.16 114.09s51.66 114.09 115.16 114.09c30.76 0 59.68-11.87 81.43-33.42a11.474 11.474 0 0 0 0-16.33z'
      />
      <path
        fill='#FFFFFF'
        d='m129.5 259.75 2.66 4.56 53.33 91.58c3.27 5.61 9.35 9.1 15.88 9.1 6.53 0 12.61-3.49 15.88-9.1l53.33-91.58 2.66-4.56c-19.63 15.87-44.64 25.39-71.87 25.39-27.23 0-52.23-9.52-71.87-25.39z'
      />
    </svg>
  )
}

export default LogoLight
