import React, { createContext, useCallback, useState } from 'react'
import { useScreenSize } from '../../utils'
import { MenuStatuses } from '../../constants'

export const MenuCtx = createContext({
  toggleMenu: () => {},
  temporaryOpenMenu: () => {},
  onOutsideClick: () => {}
})

export const MenuProvider = ({ children }) => {
  const { isLarge } = useScreenSize()
  const [menuStatus, setMenuStatus] = useState(
    isLarge ? MenuStatuses.Opened : MenuStatuses.Closed
  )

  const toggleMenu = useCallback(({ event }) => {
    setMenuStatus(
      prevMenuStatus => prevMenuStatus === MenuStatuses.Closed
        ? MenuStatuses.Opened
        : MenuStatuses.Closed
    )
  }, [])

  const temporaryOpenMenu = useCallback(() => {
    setMenuStatus(
      prevMenuStatus => prevMenuStatus === MenuStatuses.Closed
        ? MenuStatuses.TemporaryOpened
        : prevMenuStatus
    )
  }, [])

  const onOutsideClick = useCallback(() => {
    setMenuStatus(
      prevMenuStatus => prevMenuStatus !== MenuStatuses.Closed && !isLarge
        ? MenuStatuses.Closed
        : prevMenuStatus
    )
    return menuStatus === MenuStatuses.Closed
  }, [isLarge, menuStatus])

  return (
    <MenuCtx.Provider value={{
      menuStatus,
      setMenuStatus,
      toggleMenu,
      temporaryOpenMenu,
      onOutsideClick
    }}
    >
      {children}
    </MenuCtx.Provider>
  )
}
