import React, { useEffect } from 'react'
import { usePage } from '@inertiajs/react'
import { toast } from 'sonner'

const ToastMessages = () => {
  const { flash } = usePage().props

  useEffect(() => {
    if (flash.success) {
      toast.success(flash.success)
    }
    if (flash.error) {
      toast.error(flash.error)
    }
    if (flash.warning) {
      toast.error(flash.warning)
    }
  }, [flash])

  return (
    <></>
  )
}

export default ToastMessages
