import { MODEL_TYPE, MODEL_TYPE_KEY_BY_VALUE } from '../constants/index.jsx'
import { MODEL_PHASE_TYPE } from '../constants/model_phase_types.jsx'

const lineAllColumns = [
  'type',
  'model_id',
  'description',
  'description_2',

  'product_id',
  'product_type',

  'package_uom_id',
  'package_quantity',

  'uom_id',
  'quantity',

  'quantity_to_return',
  'quantity_returned',

  'quantity_to_order',
  'quantity_ordered',
  'outstanding_quantity_to_order',

  'quantity_to_ship',
  'quantity_shipped',
  'outstanding_quantity_to_ship',

  'quantity_to_invoice',
  'quantity_invoiced',
  'outstanding_quantity_to_invoice',

  'list_price',
  'list_price_exc_vat',

  'price',
  'price_exc_vat',

  'discount_percentage',
  'unit_discount_exc_vat',
  'unit_discount',
  'discount_amount',
  'discount_total',

  'base_amount',
  'amount',
  'vat_product_group_id',
  'vat_percent',
  'vat_amount',
  'base_total',
  'total',

  'note',

  'fixed_asset_id',
  'small_inventory_id'
]

const lineReturnBlockColumns = lineAllColumns.filter(f => f !== 'quantity_to_return')

const DOCUMENT_LINE_HELPER_FUNCTION = {
  lineHasShipping: (type) => {
    return [
      MODEL_TYPE.SALES_ORDER,
      MODEL_TYPE.SALES_RETURN_ORDER,
      MODEL_TYPE.PURCHASE_ORDER,
      MODEL_TYPE.PURCHASE_RETURN_ORDER
    ].includes(type)
  },
  lineHasInvoicing: (type) => {
    return [
      MODEL_TYPE.SALES_ORDER,
      MODEL_TYPE.SALES_RETURN_ORDER,
      MODEL_TYPE.PURCHASE_ORDER,
      MODEL_TYPE.PURCHASE_RETURN_ORDER
    ].includes(type)
  },
  lineHasOrdering: (type) => {
    return [
      MODEL_TYPE.SALES_CONTRACT,
      MODEL_TYPE.PURCHASE_CONTRACT
    ].includes(type)
  },
  lineAllColumns,
  lineReturnBlockColumns
}

const DOCUMENT_RETURN_FUNCTION = {
  lineHasReturning: (documentHeader) => {
    return documentHeader &&
      documentHeader.status_phase_type === MODEL_PHASE_TYPE.CLOSED &&
      [
        MODEL_TYPE.SALES_RETAIL_TRANSACTION,
        MODEL_TYPE.SALES_INVOICE,
        MODEL_TYPE.SALES_ORDER,
        MODEL_TYPE.SALES_SHIPMENT,
        MODEL_TYPE.PURCHASE_INVOICE,
        MODEL_TYPE.PURCHASE_ORDER,
        MODEL_TYPE.PURCHASE_RECEIPT
      ].includes(documentHeader.type)
  },
  getReturnDocument: (documentHeader) => {
    const map = {
      [MODEL_TYPE.SALES_RETAIL_TRANSACTION]: MODEL_TYPE.SALES_RETAIL_REFUND_TRANSACTION,
      [MODEL_TYPE.SALES_INVOICE]: MODEL_TYPE.SALES_CREDIT_MEMO,
      [MODEL_TYPE.SALES_ORDER]: MODEL_TYPE.SALES_RETURN_ORDER,
      [MODEL_TYPE.SALES_SHIPMENT]: MODEL_TYPE.SALES_RETURN_SHIPMENT,
      [MODEL_TYPE.PURCHASE_INVOICE]: MODEL_TYPE.PURCHASE_CREDIT_MEMO,
      [MODEL_TYPE.PURCHASE_ORDER]: MODEL_TYPE.PURCHASE_RETURN_ORDER,
      [MODEL_TYPE.PURCHASE_RECEIPT]: MODEL_TYPE.PURCHASE_RETURN_RECEIPT
    }

    return map[documentHeader.type]
  },
  getReturnDocumentCode: (documentHeader) => {
    return MODEL_TYPE_KEY_BY_VALUE(DOCUMENT_HELPER.getReturnDocument(documentHeader)).toLowerCase()
  }
}

const DOCUMENT_HEADER_HELPER_FUNCTION = {
  headerHasShipping: (type) => {
    return [
      MODEL_TYPE.PURCHASE_CONTRACT,
      MODEL_TYPE.PURCHASE_ORDER,
      MODEL_TYPE.PURCHASE_RETURN_ORDER,
      MODEL_TYPE.PURCHASE_INVOICE,
      MODEL_TYPE.PURCHASE_CREDIT_MEMO,
      MODEL_TYPE.PURCHASE_RECEIPT,
      MODEL_TYPE.PURCHASE_RETURN_RECEIPT,
      MODEL_TYPE.SALES_CONTRACT,
      MODEL_TYPE.SALES_ORDER,
      MODEL_TYPE.SALES_RETURN_ORDER,
      MODEL_TYPE.SALES_INVOICE,
      MODEL_TYPE.SALES_CREDIT_MEMO,
      MODEL_TYPE.SALES_SHIPMENT,
      MODEL_TYPE.SALES_RETURN_SHIPMENT,
      MODEL_TYPE.SALES_RETAIL_TRANSACTION,
      MODEL_TYPE.SALES_RETAIL_REFUND_TRANSACTION
    ].includes(type)
  }
}

export const DOCUMENT_HELPER = {
  ...DOCUMENT_LINE_HELPER_FUNCTION,
  ...DOCUMENT_RETURN_FUNCTION,
  ...DOCUMENT_HEADER_HELPER_FUNCTION
}
