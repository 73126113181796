import axios from 'axios'
import { format } from 'date-fns'
import { toast } from 'sonner'
import { Trans } from 'react-i18next'
import { DateFormat } from '../constants/index.jsx'

export const DataSourceCallbacks = {
  /**
   * Define load
   * @param options
   * @param url
   * @returns {Promise<{summary: *, data: *, groupCount: *, totalCount: *}>}
   */
  load: (options, url) => {
    return axios.get(url, {
      params: options
    }).then(res => {
      const result = res.data

      return {
        data: result.data,
        totalCount: result.totalCount,
        summary: result.summary,
        groupCount: result.groupCount
      }
    }).catch(err => {
      throw new Error(err.response.data.message)
    })
  },
  /**
   * Define insert
   * @param url
   * @param values
   * @returns {Promise<axios.AxiosResponse<any>>}
   */
  insert: (url, values) => {
    for (const [key, value] of Object.entries(values)) {
      if (value && value instanceof Date) {
        values[key] = format(value, DateFormat.serialization.dateTime)
      }
    }

    return axios.post(url, values).then(res => {
      toast.success(<Trans i18nKey='messages.successfully_added' />)
    }).catch(err => {
      throw new Error(err.response.data.message)
    })
  },
  /**
   * Define update
   * @param url
   * @param values
   * @returns {Promise<axios.AxiosResponse<any>>}
   */
  update: (url, values) => {
    for (const [key, value] of Object.entries(values)) {
      if (value && value instanceof Date) {
        values[key] = format(value, DateFormat.serialization.dateTime)
      }
    }

    return axios.put(url, values).then(res => {
      toast.success(<Trans i18nKey='messages.successfully_updated' />)
    }).catch(err => {
      throw new Error(err.response.data.message)
    })
  },
  /**
   * Define remove
   * @param url
   * @returns {Promise<axios.AxiosResponse<any>>}
   */
  remove: (url) => {
    return axios.delete(url).then(res => {
      toast.success(<Trans i18nKey='messages.successfully_deleted' />)
    }).catch(err => {
      throw new Error(err.response.data.message)
    })
  },
  /**
   * Define byKey
   * @param url
   * @returns {Promise<axios.AxiosResponse<any>>}
   */
  byKey: url => {
    return axios.get(url).then(res => {
      return res.data
    }).catch(err => {
      throw new Error(err.response.data.message)
    })
  }
}
