import React, { useCallback, useContext, useEffect, useMemo, useRef } from 'react'
import { buildNavigation } from './app-navigation'
import * as events from 'devextreme/events'
import { usePage } from '@inertiajs/react'
import TreeView from 'devextreme-react/tree-view'
import { useTranslation } from 'react-i18next'
import { Link } from '../../utils'
import { CirclePlus, ChevronsUpDown, ChevronsDownUp } from 'lucide-react'
import { AppCtx } from '../providers/AppProvider'

const SideNavigationMenu = (props) => {
  const { auth, url, app } = usePage().props
  const { t } = useTranslation()
  const {
    selectedItemChanged,
    openMenu,
    compactMode,
    onMenuReady
  } = props
  const { links } = useContext(AppCtx)

  const navigation = useMemo(() => buildNavigation(links, auth, url), [auth, app.locale])

  function normalizePath () {
    return navigation.map((item) => (
      { ...item, path: item.path && !(/^\//.test(item.path)) ? `/${item.path}` : item.path }
    ))
  }

  const items = useMemo(
    normalizePath,
    // [auth]
    [app.locale]
  )

  const treeViewRef = useRef(null)
  const wrapperRef = useRef()
  const getWrapperRef = useCallback((element) => {
    const prevElement = wrapperRef.current
    if (prevElement) {
      events.off(prevElement, 'dxclick')
    }

    wrapperRef.current = element
    events.on(element, 'dxclick', (e) => {
      openMenu(e)
    })
  }, [openMenu])

  useEffect(() => {
    const treeView = treeViewRef.current && treeViewRef.current.instance
    if (!treeView) {
      return
    }

    treeView.unselectAll()

    if (url.current !== undefined) {
      const currentUrl = url.current.startsWith('/', 0) ? url.current : `/${url.current}`
      treeView.selectItem(currentUrl)
      treeView.expandItem(currentUrl)

      treeView.element().querySelectorAll('.dx-treeview-item')
        .forEach(item => {
          item.classList.remove('parent-active')
        })

      const selectedNodes = treeView.getSelectedNodes()

      if (selectedNodes && selectedNodes.length > 0) {
        setParentNodeActive(selectedNodes[0], treeView)
      }
    }

    if (compactMode) {
      treeView.collapseAll()
    }
  }, [compactMode, url])

  const setParentNodeActive = (node, component) => {
    if (node.parent) {
      const parentEl = component.element().querySelectorAll("[data-item-id='" + node.key + "'] > .dx-treeview-item")[0]
      parentEl?.classList.add('parent-active')

      return setParentNodeActive(node.parent, component)
    }

    const parentEl = component.element().querySelectorAll("[data-item-id='" + node.key + "'] > .dx-treeview-item")[0]
    parentEl?.classList.add('parent-active')

    return node
  }

  const itemTemplate = (item) => {
    if (item.path) {
      return (
        <div className={`dx-menu-link-wrap ${item.new && item.new.show ? 'has-create' : ''}`}>
          <a className='dx-item-content' href={item.path} onClick={e => e.preventDefault()}>
            {item.icon &&
              <i className='dx-icon dx-svg-icon'>
                <div dangerouslySetInnerHTML={{ __html: item.icon }} />
              </i>}
            <span>{item.text}</span>
          </a>
          {item.new && item.new.show &&
            <Link
              className='dx-menu-create-button'
              href={item.new.path}
            ><CirclePlus size={16} />
            </Link>}
        </div>
      )
    }

    return (
      <>
        {item.icon &&
          <i className='dx-icon dx-svg-icon'><div dangerouslySetInnerHTML={{ __html: item.icon }} /></i>}
        <span>{item.text}</span>
      </>
    )
  }

  return (
    <div
      className='side-navigation-menu bg-navyGreen'
      ref={getWrapperRef}
    >
      <div className='menu-container p-2'>
        <div className='flex mb-2 w-full items-center justify-end'>
          <button
            type='button'
            className='p-1 rounded text-white hover:bg-tealGreen'
            title={t('ui.expand_all')}
            onClick={() => treeViewRef.current?.instance.expandAll()}
          >
            <ChevronsUpDown size={13} />
          </button>
          <button
            type='button'
            className='ml-1 p-1 rounded text-white hover:bg-tealGreen'
            title={t('ui.collapse_all')}
            onClick={() => treeViewRef.current?.instance.collapseAll()}
          >
            <ChevronsDownUp size={13} />
          </button>
        </div>
        <TreeView
          ref={treeViewRef}
          items={items}
          itemRender={itemTemplate}
          keyExpr='path'
          selectionMode='single'
          focusStateEnabled={false}
          expandEvent='click'
          onItemClick={selectedItemChanged}
          onContentReady={onMenuReady}
          width='100%'
          onItemRendered={e => {
            let menu = window.localStorage.getItem('menu')

            if (menu) {
              menu = JSON.parse(menu)

              menu.forEach(key => {
                e.component.instance().expandItem(key)
              })
            }

            if (e.itemData.selected) {
              setParentNodeActive(e.node, e.component)
            }
          }}
          onInitialized={(e) => {
            let menu = window.localStorage.getItem('menu')

            if (menu) {
              menu = JSON.parse(menu)

              menu.forEach(key => {
                e.component.instance().expandItem(key)
              })
            }
          }}
          onItemExpanded={(e) => {
            let menu = window.localStorage.getItem('menu')

            if (menu) {
              menu = JSON.parse(menu)
            } else {
              menu = []
            }

            if (menu.indexOf(e.node.key) === -1) {
              menu.push(e.node.key)
            }

            window.localStorage.setItem('menu', JSON.stringify(menu))
          }}
          onItemCollapsed={(e) => {
            let menu = window.localStorage.getItem('menu')

            if (menu) {
              menu = JSON.parse(menu)
            } else {
              menu = []
            }

            if (menu.indexOf(e.node.key) > -1) {
              menu.splice(menu.indexOf(e.node.key), 1)
            }

            window.localStorage.setItem('menu', JSON.stringify(menu))

            if (e.node.items) {
              e.node.items.forEach(i => {
                e.component.instance().collapseItem(i.key)
              })
            }
          }}
        />
      </div>
    </div>
  )
}

export default SideNavigationMenu
