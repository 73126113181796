import React, { useCallback, useContext, useRef } from 'react'
import ScrollView from 'devextreme-react/scroll-view'
import { Template } from 'devextreme-react/template'
import SideNavigationMenu from './SideNavigationMenu'
import Drawer from 'devextreme-react/drawer'
import { useMenuPatch, useScreenSize } from '../../utils'
import { MenuStatuses } from '../../constants'
import { router } from '@inertiajs/react'
import { MenuCtx } from '../Menu'

const MainContent = ({ children, fullGrid }) => {
  const { menuStatus, setMenuStatus, temporaryOpenMenu, onOutsideClick } = useContext(MenuCtx)
  const { isXSmall, isLarge } = useScreenSize()
  const [patchCssClass, onMenuReady] = useMenuPatch()
  const scrollViewRef = useRef(null)

  const onNavigationChanged = useCallback(({ itemData, event, node }) => {
    if (menuStatus === MenuStatuses.Closed || !itemData.path || node.selected) {
      event.preventDefault()
      return
    }

    if (!event.target.closest('a')?.classList.contains('dx-menu-create-button')) {
      router.visit(itemData.path)
    }

    scrollViewRef.current.instance.scrollTo(0)

    if (!isLarge || menuStatus === MenuStatuses.TemporaryOpened) {
      setMenuStatus(MenuStatuses.Closed)
      event.stopPropagation()
    }
    // }, [navigate, menuStatus, isLarge])
  }, [menuStatus, isLarge])

  return (
    <Drawer
      className={['drawer', 'flex-grow', patchCssClass].join(' ')}
      position='before'
      closeOnOutsideClick={onOutsideClick}
      openedStateMode={isLarge ? 'shrink' : 'overlap'}
      revealMode={isXSmall ? 'slide' : 'expand'}
      minSize={isXSmall ? 0 : 48}
      maxSize={250}
      shading={!isLarge}
      opened={menuStatus !== MenuStatuses.Closed}
      template='menu'
    >
      <ScrollView ref={scrollViewRef} className={`layout-body ${fullGrid ? 'full-grid-wrap' : ''}`}>
        <div className='content'>
          <main className='flex flex-col h-full'>
            {children}
          </main>
        </div>
      </ScrollView>

      <Template name='menu'>
        <SideNavigationMenu
          compactMode={menuStatus === MenuStatuses.Closed}
          selectedItemChanged={onNavigationChanged}
          openMenu={temporaryOpenMenu}
          onMenuReady={onMenuReady}
        />
      </Template>
    </Drawer>
  )
}

export default MainContent
