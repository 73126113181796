import React, { createContext, useState } from 'react'
import enumOptions from '../../enumOptions.json'
import enumConstants from '../../enumConstants.json'
import links from '../../links.json'
import i18n, { t } from 'i18next'

export const AppCtx = createContext({})

export const AppProvider = ({ children, settings }) => {
  const [language, setLanguage] = useState(i18n.language)

  const enumOptionsWithTranslations = Object.assign({}, ...Object.entries(enumOptions)
    .map(([k, v]) => {
      return ({
        [k]: v.map(item => ({
          ...item,
          label: t(item.label)
        }))
      })
    }))

  return (
    <AppCtx.Provider value={{
      enumOptions: enumOptionsWithTranslations,
      enumConstants,
      settings,
      language,
      setLanguage,
      links
    }}
    >
      {children}
    </AppCtx.Provider>
  )
}
